.contact-container{
    display: none;
}
.privacy-policy{
    margin-top: .5rem;
    display: block;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    text-decoration: underline;
}
.privacy-policy:hover{

    cursor: pointer;
}
.sumbit-contact{
    padding: 0px;
    align-self: center;
    height: 60px;
    width: 256px;
    margin-top: 10px;
    background: #fff6df;
    border: none;
    margin-bottom: 10%;
}


.sumbit-contact:hover{
   
    cursor: pointer !important;
}
.sumbit-contact-inner-box{
    display: flex;
    border: 2px solid #00B8D4;
   justify-content: center;
   align-items: center;
   height: 96%;
   width: 99%;
   margin: 1px;
   color: black;
   font-size: 2.3rem;
   font-weight: 600;
    direction: ltr;
}
.subjects-list>li{
    margin:15px 5px ;
    font-size: 1.3rem;
    font-weight: 500;
}
.subjects-list>li.ltr-list-item::after,
.subjects-list>li:not(.ltr-list-item)::before{
    content: '\f560';
    font-family: 'Font Awesome 5 Free';
    font-weight:900;
    color: green;
    margin-left: 10px;
}

.subjects-list{
    list-style: none;
    padding: 15px;
    margin: 0px;
}
.ltr-list-item{
   
}
@media (min-width:925px){
.contact-container{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background:linear-gradient(155deg,#154b9c ,#213b7f) !important;
    min-width: 290px;
    height: 490px;
    color: white;
    margin-top: 100px;
    position: relative;
    left: 1vw;

    
}
.contact-container>h2{
    display: block;
    
    align-self: end;
    margin-right: 1.5rem;
    padding-bottom: 15px;
}

.input-container>h2{
    margin: 12px;
    text-align: end;
}
.input-container{
    display: flex;
    
    flex-flow: column;
    align-content: space-between;
    }
    .agree-checkbox{
        margin-top: 5%;
        display: flex;
        align-items:flex-start;
        justify-content: center;
        direction: ltr;
    }
    .checkbox{
        margin-right: 5%;
    }
    .agree-explain{
        margin-right: 2%;
        direction: rtl;
        
        font-size: 13px;
        font-weight: 700;
    }
    
    form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .contact-container.hidden{
       max-height: 50px;
       max-width: 30px;
        justify-content: flex-start;
        overflow: hidden;

    }
}