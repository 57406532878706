.quation-answer-container{
    
    direction: rtl;
    border: .5px solid #dbdbdb;
    padding: 0px 10px;
    border-right: none;
    border-left: none;
   width: 100%;
}
.answer{
    /* font-size: .95rem; */
    max-height: 0px;
    overflow: hidden;
    transition: all .2s ease-in-out;
    
}
.answer-link{
    display: block;
    text-decoration: underline;
    color: blue;
    font-weight: 600;

   
}
.answer-link:visited{
    color: blue;
}
.answer.show{
    max-height: 800px;
    margin-bottom: 15px;
}
.link-inline{
    display: inline;
}
.quation{
    font-weight: 600;
    font-size: 1.1rem;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
    padding: 5px;
    color: #007cba;
   
}
@media (min-width:925px){
    .quation-answer-container{
        width: 450px;
     
    
    flex-grow: 0;
    flex-shrink: 0;
       margin: 0px 17px;
  justify-content: center;
     border-bottom: none;
    }
}