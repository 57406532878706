.costum-input{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: white;
    width: 250px;
    height: 60px;
    direction: rtl;
margin: 12px;
}

.costum-input>label{
  width:25%;
  margin-left: 10px;
  font-size: .9rem;
  white-space: nowrap;
  padding-right: 5px;
  color: #373737;
  font-weight: 700;
}
.costum-input>input{
    width: 70%;
    max-width: 250px;
    height: 100%;
    border: none;
}
.costum-input>input::placeholder{
    color: transparent;
}
.costum-input>input.unvalid::placeholder{
    color: red;
}
.costum-input>input:focus{
    outline: none;
    direction: rtl;
}
.separetor{
    position: relative;
  float: left;
  
   background:#213b7f;
   width: 3px;
   height: 1.3rem;
   border-radius: 20px;
   flex-shrink: 0;
   
}