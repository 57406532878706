.contact-footer{
    /* margin-top: 50px; */
    padding-bottom: 50px;
    display: flex;
    flex-flow: column;
    background: #213b7f;
    justify-content: center;
    align-items: center;
    color: white;
}
.contact-footer>h2{
    text-align: start;
    direction: rtl;
    font-size: 1.2rem;
    margin:15px 10px ;
    margin-top: 0px;
    padding-top: 10px;
}
.input-container.footer{
    display: flex;
    flex-flow: column;
  width: 100%;
}
.contact-footer>form,
.footer>form{
    width: 70%;
    margin: 0px auto;
}
.sumbit-contact.footer{
   margin: 0px auto;
}
.agree-checkbox.footer{
    margin-top: 10px;
    font-size: .8rem;
    display: flex;
    text-align: start;
    direction: rtl;
    flex-flow: row-reverse;
    justify-content: center
}
.footer-input{
    width: 100%;
    margin: 25px auto;
}
.privacy-policy{
    color:white
}
@media (min-width:925px){
    .contact-footer{
        margin-top: 0px;
        width: 100%;
    }
    
    .input-container.footer{
      
        flex-flow: row-reverse;
       align-items: center;
       justify-content: center;
       flex-wrap: wrap;
      max-width: 950px;
      margin: 0px auto;
    }
    .input-container.footer>.footer-input{
        width: 22%;
        max-width: 260px;
        margin: 25px 5px;
        flex-shrink: 5;
    }
    .sumbit-contact.footer{
    
     }
     .contact-footer>form{
         width: 100%;
     }
}
