.courses-container{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 90%;
    flex-wrap: wrap;
    direction: rtl;
    margin-bottom: 0px;
    animation: show .8s;
}
@media (min-width:925px){
    .courses-container{
        display: grid;
       
        height: 400px;
        gap: 5px;
        grid-template-columns:repeat(4,25%) ;
        grid-template-rows:repeat(2,50%) ;
        direction: rtl;
        /* margin-left: 30px; */
        align-items: center;
        justify-items: center;
        display: flex;
        width: 650px;
        flex-wrap: wrap;
        margin-top: 0px;
        /*margin-right: 30px;
        margin-bottom: 200px; */
        padding-bottom: 50px;
    }
}

#net-securety-data-cloud-manegment>.course-separetor{
    background:#a3b6cc ;
}
#cyber-data>.course-separetor{
background: #4ac694 ;
}
#digital-marketing>.course-separetor{
    background: #3a5998  ;
}
#QA>.course-separetor{
    background: #316cff   ;
}
#grhapic-ui-ux>.course-separetor{
    background: #f9cf58   ;
}
#fullstack-dev>.course-separetor{
background: #9da6e9 ;
}
#mobile-dev>.course-separetor{
    background: #8e8e93 ;
}
#server-linux-manegment>.course-separetor{
    background:#475f8e 
}
#anime-3d>.course-separetor{
    background:#F36E44
}
#game-dev>.course-separetor{
    background:#D72816  
}
#DBA-master>.course-separetor{
    background:#25B9A2 
}
#progremer-NET>.course-separetor{
    background:#39A4DC 
}
#optimaizeQA>.course-separetor{
    background:#352F8A 
}