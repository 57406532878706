.email-modal{
    position: fixed;
    top:0px;
    left: 0px;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background: none 0% 0% / auto repeat scroll padding-box border-box rgba(0, 0, 0, 0.31);;
    display: flex;
    justify-content: center;
    align-items: center;
}
.email-modal.hide{
    display: none;
}
.email-modal-content{
    height: 400px;
    width: 600px;
    background: white;
    position: relative;
    padding: 0px 15px;
    padding-top: 25px;
    display: flex;
    flex-flow: column;

    align-items: center;
}
.email-modal-title{
    color: black;
    direction: rtl;
    font-size: 4rem;
    margin:0px;
    margin-bottom: 10px;
    
    margin-top: -10px;
    text-align: center;
  
}
.modal-text{
    text-align: start;
    color: black;
   
    font-size: 1.5rem;
    direction: rtl;

}
.email-modal-content>.fa-check-circle{
    color: green;
    font-size: 5rem;
    align-self: flex-start;
    margin:20px 20px 0px 30px;
}
.email-modal-content>.fa-times-circle{
    color: red;
    font-size: 5rem;
    align-self: flex-start;
    margin:20px 20px 0px 30px;
}
.email-modal-content>.fa-times{
    color: black;
    font-size: 1.8rem;
    margin-top: -10px;
    align-self: flex-start;
    margin-left: 10px;
}
@media(min-width:925px){
    .modal-text{
    font-size: 2.4rem;}
}
