.navigation-bar{
    width: 100%;
    height: 150%;
    position: relative;
    top: 0px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-content: center;
    vertical-align: middle;
    align-self: flex-end;
    background: white;
    z-index: 100;
}
.navigation-bar.mobile-open{
margin-bottom: 30px;
}
.navigation-container{
    position: relative;
 display: flex;
 flex-flow: column;
 width:100%;
top: 0px;
justify-content: flex-start;


z-index:5;
direction: rtl;
}
.drop-down-container{
    display: none;
}
.drop-down-container.mobile-open{
   display: flex;
   flex-flow: column;
   direction: rtl;
   
   margin-bottom: 20px;
   
  position: relative;
    top: 50px;
    left: 140px;
    background: white;
    width: 210%;
    min-width: 170px;
    list-style: none;

}

.nav-link{
    font-size: 1.25rem;
    margin: 10px 0px;
    width: 200%;
    display: block;
}
.nav-link>.fas{
    margin-right: 5px;
    position: relative;
    top: -2px;
}
.drop-down>a,
.drop-down>li{
    display: none;
}
.mobile-open>.drop-down.open>a,
.mobile-open>.drop-down.open>li{
    display: block;
    position: relative;
    color:#aad6be ;
    font-size: 1.2rem;
    margin: 5px;
    padding: 10px;
    width: 95%;
    position: relative;
    width: 100vw;
     font-size: 1.2rem;
     margin: 10px 0px;
     color: black !important;
     border-bottom: 1px dashed #aad6be;
}
.mobile-open>.drop-down>a,
.mobile-open>.drop-down>li{
    position: relative;
   width: 100vw;
    font-size: 1.2rem;
    margin: 10px 0px;
    color: black !important;
    border-bottom: 1px dashed #aad6be;

}

.logo-container{
    height: 60px;
    
  margin-top: -7px;
  width: min-content;
  flex-shrink: 0;
  float: right;
}
.logo-container>.nav-subtitle{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    top: -32px;
    right: -25px;
    right: 15px;
    font-size: .6rem;
    font-weight: 700;
    margin: 0px;

}
.logo{    
    margin:0px auto  5px auto;
 flex-shrink: 0;
 
 height: 100%;
    object-position: 0px;
 object-fit: cover;
}
.menu-button{
  margin: 7px 0px auto 10px;
    width:40px;
    height: 40px;
    position: relative;
align-self: flex-start;
order:-1;
z-index: 1000;
}
.menu-button.open>#menu-line-top{
 
   transform: translateY(24px) rotate(45deg) ;
}
.menu-button.open>#menu-line-bottom{
    transform: rotate(-45deg);
 }
 .menu-button.open>#menu-line-middle{
    transform: translate(10px);
        opacity: 0;
 
 }
 a,
li{
    cursor:default;
}

.menu-line{
    height: 5px;
    width: 40px;
    background: #213a7f;
    margin: 7px 0px;
    border-radius:30px ;
    transition: all linear .2s;
}

.nav-links-container{
    display: flex;
    flex-flow: column;
  
   width: 0px;

}
.drop-down-container.mobile-open{
   
    padding-right: 20px;
}
@media (min-width:925px){
    .drop-down:not(.hide):hover,
    .nav-link:hover+.drop-down{
        display: flex;
    position: absolute;
        flex-flow: column;
        direction: rtl;
      top: 41px;
         flex-wrap: wrap;
         background:#213a7f;
         border: 1px solid rgba(0,0,0,.15);
        box-shadow:  0 6px 12px rgba(0,0,0,.175);
          
        height: 100%;
   
       width: -webkit-fill-available;
       width:  -moz-available;   
         
        height: 35vh;
        opacity: .99;
         color: #aad6be;
         font-weight: 700;
         list-style: none;
align-items: flex-start;

    }
    .drop-down:hover>a,
    .drop-down:hover>li,
    .nav-link:hover+.drop-down>a,
    .nav-link:hover+.drop-down>li{
        display: inline;
        color: white !important;
    }
    .drop-down>a:hover,
.drop-down>li:hover{
    color:#213a7f !important ;
    background: white;
}
    .logo-container{
        height: 80px;
        margin-right: 30px;
    }
    .logo{
        position: relative;
        top:5px;
    margin-right: -100px;
    height: 75px;
    }
    .logo-container>.nav-subtitle{
        
        right: -25px;}
    .drop-down>a,
    .drop-down>li{
        font-size:1rem;
        margin: 7px 10px;
        width: max-content;
        flex-basis: content;
        color:#aad6be !important
        }
    .navigation-bar{
        position: sticky;
        top: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
      
    }
    .drop-down-container{
        display: flex;
        flex-flow: row-reverse;
        justify-content: flex-end;
        align-items: flex-end;
     
        align-self: flex-end;
        direction: rtl;
        margin: 10px 2px;
    }
    .menu-button{
        display: none;
    }
    .drop-down-container>a,
    .drop-down-container>li{
        list-style-type: none;
        direction: rtl;
        padding-right: 0px;
        font-weight: 400;
        font-size: 16px;
     
    }
    .nav-link{
        list-style-type: none;
        margin: 0px;
        padding: 0px;
        flex-shrink: 0;
       width: unset;
       
    }
    
    .splitter:after{
        content: '|';
        font-size: 1.5rem;
        margin-right: 5px;
        color: black !important;
        font-weight: 400!important;
        transform: scale(1,1) !important;
    }
   
   
    .nav-link:hover{
      color:#213a7f;
      font-weight: 700;
    }
    .navigation-container{
     flex-flow: row;
     align-items: flex-end;
 justify-content: flex-start;
 flex-flow: row;
 height: 60px;
 width: max-content;
    }
    
    .nav-links-container{
        position: sticky;
        display: flex;
        flex-flow: row;
        width: 100%;
        
    }
    .non-splitter{
        margin-bottom: 2px;
    }
    
}
a{
    color: black;
    text-decoration: none;
    display: flex;
}
a:visited{
    color: initial;
    font-style: none;
    text-decoration: none;
}
a::selection{
    color: initial;
   
}