.whatsapp-connect{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width:255px;
    height: 35px;
    position: fixed;
    background: #0066ff;
    top:95vh;
    right: 1vw;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
    color: white;
    font-weight: 600;
    font-size: 1.3rem;
    border-radius: 15px;
    border:2px solid  #25d366;
}
.whatsapp-connect:hover{
    cursor: pointer;
}
@media (max-width:450px){
    .whatsapp-connect{
        display:none;
    }
}