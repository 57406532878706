@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@600&display=swap');
html{
    scroll-behavior: smooth;
    
}
body{
    margin: 0px;
 
    overflow-x: hidden;
    overflow-y: visible;
    
   
}
:root{
    --break-point:980px;
}
*{
    box-sizing: border-box;
    font-family: 'Assistant', sans-serif;
    
   
}

p{
    font-size: 1.1rem;
    cursor: default;
}
a:hover{
    cursor: pointer;
}
.hide{
    display: none !important;
}
.green-bg{
    background: green;
}
.cream-bg{
    background: #eff6df;
    color: black
}
.white-bg{
    background: white;
}
.mobile-only{
    display: ;
}
.underline-text{
    text-decoration: underline;
}
.margin-10r{
    margin-right: 10px;
}
.absolute-wrapper{
    position: absolute;
}
.inline-item{
    display: inline;
}
@media (min-width:925px){
    .mobile-only{
        display: none;
    }
}
@keyframes show{
    from{
       transform: translateY(50px);
       opacity: 0;
    }
    to{
       transform: translateY(0px);
       opacity: 1;
  
    }
}
.desktop-only{
    display: none;
}
@media (min-width:925px){
    .desktop-only
    {display: unset;}
}