.contact-bar-container{
   position: sticky;
    bottom:0px;
    width:100%;
    background: #0066ff;
    display: flex ;
    justify-content: center;
    height: 60px;
}
@media(max-width:925px){
.contact-bar-container{
    display: flex !important;
}
}
.contact-btn{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 500;
    color: white;
    
   
}
.contact-btn:visited,
.contact-btn::selection{
    color: white;
}
.contact-btn:hover>.hover-underline{
    text-decoration: underline;
}
.border-right{
    border-right: 2px solid white;
}
.far.fa-hand-point-up,
.fab.fa-whatsapp{
margin-top: 2px;
    margin-left: 5px;
    
}