.categories-container {
    padding: 20px;
    width: 100%;
  }
  .categories-container ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    width: 100%;
  }
  
  .category-container {
    padding: 20px 20px;
    margin: 10px 0px;
    width: 100%;
  }
  
  .category-more-link > .fas {
    display: inline;
  }
  
  .plus-icon {
    width: 15px;
    height: 15px;
    margin-left: 12px;
    transition: rotate 0.5s linear;
    rotate: 0deg;
  }
  .plus-icon.become-exit-button {
    rotate: 45deg;
  }
  
  .category-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.3rem;
  }
  
  .category-inner-menu {
    transition: max-height 0.3s linear;
    max-height: 900px;
    opacity: 1;
  }
  .category-inner-menu ul li {
    font-size: 0.9rem;
    margin: 15px 0px;
  }
  @media (min-width: 1205px) {
    .categories-container {
      display: flex;
      justify-content: center;
      margin: 0px auto;
      margin-bottom: 300px;
      width: 100%;
   
      
    }
    .categories-container ul {
      flex-shrink: 0;
      width: 1100px;
      display: grid;
      grid-template-rows: repeat(0, 18.75rem);
      grid-template-columns: repeat(4, 25%);
      display: flex;
      
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    
    .category-container {
      display: flex;
      flex-flow: column;
      height: 65%;
      width: 50%;
      padding: 45px;
      background: none;
      margin: 0px;
    
    }
    .triangle{
      height: 0;
      width: 0;
      border: 25px solid white;
      flex-shrink: 0;
      position: relative;
    }
    .triangle-left{
      float: left;
      margin-top: -60px;
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
      right: 505px;
      top: 50px;
    
    }
    .triangle-down{
      border-right-color: transparent;
      border-bottom-color:transparent;
      border-left-color: transparent;
 
      top: 95px;
      right: 400px;
    
    }
    .triangle-right{
      border-top-color:transparent;
      border-right-color: transparent;
      border-bottom-color:transparent;
      top:-480px;
      left: 95px;
      
    }
    .plus-icon {
      display: none;
    }
  }
  .category-inner-menu.close {
    max-height: 0px;
  }
  
  .take-out-from-flow {
    display: none;
  }
  
  .category-more-link {
    display: flex;
    justify-content: flex-end;
    font-size: 0.9rem;
    align-items: center;
  }
  .category-more-link:link {
    text-decoration: none;
  }
  .category-more-link:visited {
    color: unset;
    text-decoration: none;
  }
  .category-more-link:hover {
    text-decoration: none;
  }
  .category-more-link:active {
    text-decoration: none;
  }
  .category-more-link .fas.fa-arrow-left {
    font-size: 0.9rem;
    margin-right: 5px;
  }
  
  .more-jobs-link {
    background: #d9d6d3;
    color: #202a68;
    font-size: 1.3rem;
    margin: 10px 0px;
    padding: 0px;
  }
  .more-jobs-link .fas.fa-arrow-left {
    background: #fb8e0f;
    height: 100%;
    display: inline;
    padding: 20px 20px;
    color: white;
    font-size: 1.5rem;
  }
  .more-jobs-link a {
    font-size: 1.3rem;
    color: #202a68;
    align-items: center;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
  }
  .more-jobs-link a:link {
    text-decoration: none;
  }
  .more-jobs-link a:visited {
    color: unset;
    text-decoration: none;
  }
  .more-jobs-link a:hover {
    text-decoration: none;
  }
  .more-jobs-link a:active {
    text-decoration: none;
  }
  
  #programing-category {
    background: rgb(5,152,168);
    color: white;
  }
  #programing-category>.triangle-left{
    border-right-color:  rgb(5,152,168);
  }

  #programing-category .category-inner-menu > a:hover,
  #programing-category .category-inner-menu > ul > li:hover {
    color: orange;
  }
  
  #web-category {
    background: rgb(57,164,220);
    color: white;
  }
  #web-category>.triangle-down{
    border-top-color:rgb(57,164,220);
  }
  #web-category .category-inner-menu > a:hover,
  #web-category .category-inner-menu > ul > li:hover {
    color: white;
  }
  
  #it-category {
    background: #213b7f;
    color: white;
  }
  
  #it-category .category-inner-menu > a:hover,
  #it-category .category-inner-menu > ul > li:hover {
    color: #202a68;
  }
  
  #sineor-category {
    background: #ef5227;
    color: white;
  }
  #sineor-category .category-inner-menu > a:hover,
  #sineor-category .category-inner-menu > ul > li:hover {
    color: orange;
  }
  
  #embedded-category {
    background: #0066ff;
    color: white;
  }
  #embedded-category>.triangle-right{
    border-left-color:#0066ff ;
  }
  #embedded-category .category-inner-menu > a:hover,
  #embedded-category .category-inner-menu > ul > li:hover {
    color: orange;
  }
  
  @media (min-width: 1205px) {
    #programing-category {
      grid-row: -3/-2;
      grid-column: 1/2;
      order: 1;
    }
  
    #web-category {
      grid-row: -3/-2;
      grid-column: 2/3;
      order: 2;
    }
  
    #it-category {
     
      grid-row: -3/-2;
      grid-column: 4/5;
      order: 3;
    }
  
  
    #embedded-category {
    
      grid-row: -3/-2;
      grid-column: 3/4;
      order: 4;
    }
  
    .more-jobs-link {
      width: 100%;
      height: 100%;
    }
    .more-jobs-link:hover {
      color: orange;
    }
    .more-jobs-link .fas.fa-arrow-left {
      align-self: flex-end;
      width: 73px;
      height: 73px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .more-jobs-link a {
      padding-right: 40px;
      display: flex;
      width: 100%;
      height: 100%;
    }
  
    .category-inner-menu {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      max-height: 1000px !important;
      height: 100%;
    }
    .category-inner-menu ul {
      display: inline;
      width: fit-content;
    }
    .category-inner-menu ul li {
      margin: 2px;
    }
  }