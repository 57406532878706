.privacy-modal{
    position: fixed;
    top: 0px;
    height: 100vh;
    width: 100vw;
    background:  rgba(0,0,0,0.4);
    z-index: 100;  
     padding:0px 5px;
     right: 0px;
     order: 0;
  
}

.modal-privacy-content>h3{
    margin:10px 0px;
    color:#213a7f;
    font-size: 2rem;
    text-align: center;
}
.modal-privacy-content>h6{
    margin: 5px 0px;
    color:#213a7f;
    font-size: 1.2rem;
}
.modal-privacy-content{
    background: white;
    color: black;
  
    overflow-y: scroll;
    direction: rtl;
    height: 98vh;
    padding: 5px;
    width: 92vw;
    font-size: .9rem;
    max-width: 800px;
    position: relative;
    margin: 10px auto;
    padding-bottom: 50px;
    z-index: 99999999999999;

}
.exit-button{
    margin: 5px;
}
.modal-privacy-content.hide{
    display: none;
}
@media (min-width:925px){

}