@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@600&display=swap);
.navigation-bar{
    width: 100%;
    height: 150%;
    position: relative;
    top: 0px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-content: center;
    vertical-align: middle;
    align-self: flex-end;
    background: white;
    z-index: 100;
}
.navigation-bar.mobile-open{
margin-bottom: 30px;
}
.navigation-container{
    position: relative;
 display: flex;
 flex-flow: column;
 width:100%;
top: 0px;
justify-content: flex-start;


z-index:5;
direction: rtl;
}
.drop-down-container{
    display: none;
}
.drop-down-container.mobile-open{
   display: flex;
   flex-flow: column;
   direction: rtl;
   
   margin-bottom: 20px;
   
  position: relative;
    top: 50px;
    left: 140px;
    background: white;
    width: 210%;
    min-width: 170px;
    list-style: none;

}

.nav-link{
    font-size: 1.25rem;
    margin: 10px 0px;
    width: 200%;
    display: block;
}
.nav-link>.fas{
    margin-right: 5px;
    position: relative;
    top: -2px;
}
.drop-down>a,
.drop-down>li{
    display: none;
}
.mobile-open>.drop-down.open>a,
.mobile-open>.drop-down.open>li{
    display: block;
    position: relative;
    color:#aad6be ;
    font-size: 1.2rem;
    margin: 5px;
    padding: 10px;
    width: 95%;
    position: relative;
    width: 100vw;
     font-size: 1.2rem;
     margin: 10px 0px;
     color: black !important;
     border-bottom: 1px dashed #aad6be;
}
.mobile-open>.drop-down>a,
.mobile-open>.drop-down>li{
    position: relative;
   width: 100vw;
    font-size: 1.2rem;
    margin: 10px 0px;
    color: black !important;
    border-bottom: 1px dashed #aad6be;

}

.logo-container{
    height: 60px;
    
  margin-top: -7px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  flex-shrink: 0;
  float: right;
}
.logo-container>.nav-subtitle{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    top: -32px;
    right: -25px;
    right: 15px;
    font-size: .6rem;
    font-weight: 700;
    margin: 0px;

}
.logo{    
    margin:0px auto  5px auto;
 flex-shrink: 0;
 
 height: 100%;
    object-position: 0px;
 object-fit: cover;
}
.menu-button{
  margin: 7px 0px auto 10px;
    width:40px;
    height: 40px;
    position: relative;
align-self: flex-start;
order:-1;
z-index: 1000;
}
.menu-button.open>#menu-line-top{
 
   transform: translateY(24px) rotate(45deg) ;
}
.menu-button.open>#menu-line-bottom{
    transform: rotate(-45deg);
 }
 .menu-button.open>#menu-line-middle{
    transform: translate(10px);
        opacity: 0;
 
 }
 a,
li{
    cursor:default;
}

.menu-line{
    height: 5px;
    width: 40px;
    background: #213a7f;
    margin: 7px 0px;
    border-radius:30px ;
    transition: all linear .2s;
}

.nav-links-container{
    display: flex;
    flex-flow: column;
  
   width: 0px;

}
.drop-down-container.mobile-open{
   
    padding-right: 20px;
}
@media (min-width:925px){
    .drop-down:not(.hide):hover,
    .nav-link:hover+.drop-down{
        display: flex;
    position: absolute;
        flex-flow: column;
        direction: rtl;
      top: 41px;
         flex-wrap: wrap;
         background:#213a7f;
         border: 1px solid rgba(0,0,0,.15);
        box-shadow:  0 6px 12px rgba(0,0,0,.175);
          
        height: 100%;
   
       width: -webkit-fill-available;
       width:  -moz-available;   
         
        height: 35vh;
        opacity: .99;
         color: #aad6be;
         font-weight: 700;
         list-style: none;
align-items: flex-start;

    }
    .drop-down:hover>a,
    .drop-down:hover>li,
    .nav-link:hover+.drop-down>a,
    .nav-link:hover+.drop-down>li{
        display: inline;
        color: white !important;
    }
    .drop-down>a:hover,
.drop-down>li:hover{
    color:#213a7f !important ;
    background: white;
}
    .logo-container{
        height: 80px;
        margin-right: 30px;
    }
    .logo{
        position: relative;
        top:5px;
    margin-right: -100px;
    height: 75px;
    }
    .logo-container>.nav-subtitle{
        
        right: -25px;}
    .drop-down>a,
    .drop-down>li{
        font-size:1rem;
        margin: 7px 10px;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        flex-basis: content;
        color:#aad6be !important
        }
    .navigation-bar{
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
      
    }
    .drop-down-container{
        display: flex;
        flex-flow: row-reverse;
        justify-content: flex-end;
        align-items: flex-end;
     
        align-self: flex-end;
        direction: rtl;
        margin: 10px 2px;
    }
    .menu-button{
        display: none;
    }
    .drop-down-container>a,
    .drop-down-container>li{
        list-style-type: none;
        direction: rtl;
        padding-right: 0px;
        font-weight: 400;
        font-size: 16px;
     
    }
    .nav-link{
        list-style-type: none;
        margin: 0px;
        padding: 0px;
        flex-shrink: 0;
       width: unset;
       
    }
    
    .splitter:after{
        content: '|';
        font-size: 1.5rem;
        margin-right: 5px;
        color: black !important;
        font-weight: 400!important;
        transform: scale(1,1) !important;
    }
   
   
    .nav-link:hover{
      color:#213a7f;
      font-weight: 700;
    }
    .navigation-container{
     flex-flow: row;
     align-items: flex-end;
 justify-content: flex-start;
 flex-flow: row;
 height: 60px;
 width: -webkit-max-content;
 width: -moz-max-content;
 width: max-content;
    }
    
    .nav-links-container{
        position: -webkit-sticky;
        position: sticky;
        display: flex;
        flex-flow: row;
        width: 100%;
        
    }
    .non-splitter{
        margin-bottom: 2px;
    }
    
}
a{
    color: black;
    text-decoration: none;
    display: flex;
}
a:visited{
    color: #000;
    color: initial;
    font-style: none;
    text-decoration: none;
}
a::selection{
    color: #000;
    color: initial;
   
}

.courses-title{
    display: block;
}
.course-container{
    display: flex;
    height: 153px;
    width: 128px;
    flex-flow: column;
    align-items: center;
   text-align: center;
   line-height: .9rem;
   text-decoration: none;
   margin-left: 20px;
   margin-bottom: 10px;
}

.course-separetor{
    
    width: 40px;
    height: 4px;
    margin: 10px auto 0px auto;
    border-radius: 15px;
    
}
.course-name{
    text-decoration: none;
    color: black;
    text-align: center;
    font-size: 16px;
    margin-top: 5px;
}
.course-logo{
    height: 40px;
    width: 40px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}
.course-container:hover{
    opacity: .7;
}
.course-container:focus{
    outline: none;
}
.arrow-container{
    display: none;
}
@media (min-width:925px){
    
    .course-container{
        display: flex;
        
        width: 128px;
        flex-flow: column;
        align-items: center;
        text-align: center;
        line-height: .9rem;
        text-decoration: none;
        margin-left: 20px;
        margin-bottom: 10px;
    }
    .course-separetor{
        flex-shrink: 0;
        margin: 5px 0px;
    }
    
    
}

@media (max-width:450px){
    
    .course-container{
       display: flex;
       flex-flow: row;
       width: 100%;
       height: 45px;
       min-height: 43px;
       min-width: 295px;
       align-items: center;
       
      margin-left: 0px;
      margin-bottom: 5px;
     border: 1px solid rgba(204,204,204,0.5);
     flex-shrink: 0;
      
    }
    .course-separetor{
        width: 4px ;
        height: 30px;
        border-radius: 15px;
        margin: 0px 1px;
        flex-shrink: 0;
    }
    .course_img{
        height: 40px;
        width: 40px;
        
    }
    .course-logo{
        
           
        
        margin-left: 15px;
    }
    .course-name{
        display: flex;
       margin-top: 0px;
        margin-right: 7px;
        text-align: start;
      width: 100%;
        
    }
    .arrow-container{
      display: block;
        width: 10px;
        height: 20px;
        justify-self: center;
        flex-shrink: 0;
    }
       
}




.courses-container{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 90%;
    flex-wrap: wrap;
    direction: rtl;
    margin-bottom: 0px;
    animation: show .8s;
}
@media (min-width:925px){
    .courses-container{
        display: grid;
       
        height: 400px;
        grid-gap: 5px;
        gap: 5px;
        grid-template-columns:repeat(4,25%) ;
        grid-template-rows:repeat(2,50%) ;
        direction: rtl;
        /* margin-left: 30px; */
        align-items: center;
        justify-items: center;
        display: flex;
        width: 650px;
        flex-wrap: wrap;
        margin-top: 0px;
        /*margin-right: 30px;
        margin-bottom: 200px; */
        padding-bottom: 50px;
    }
}

#net-securety-data-cloud-manegment>.course-separetor{
    background:#a3b6cc ;
}
#cyber-data>.course-separetor{
background: #4ac694 ;
}
#digital-marketing>.course-separetor{
    background: #3a5998  ;
}
#QA>.course-separetor{
    background: #316cff   ;
}
#grhapic-ui-ux>.course-separetor{
    background: #f9cf58   ;
}
#fullstack-dev>.course-separetor{
background: #9da6e9 ;
}
#mobile-dev>.course-separetor{
    background: #8e8e93 ;
}
#server-linux-manegment>.course-separetor{
    background:#475f8e 
}
#anime-3d>.course-separetor{
    background:#F36E44
}
#game-dev>.course-separetor{
    background:#D72816  
}
#DBA-master>.course-separetor{
    background:#25B9A2 
}
#progremer-NET>.course-separetor{
    background:#39A4DC 
}
#optimaizeQA>.course-separetor{
    background:#352F8A 
}
.costum-input{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: white;
    width: 250px;
    height: 60px;
    direction: rtl;
margin: 12px;
}

.costum-input>label{
  width:25%;
  margin-left: 10px;
  font-size: .9rem;
  white-space: nowrap;
  padding-right: 5px;
  color: #373737;
  font-weight: 700;
}
.costum-input>input{
    width: 70%;
    max-width: 250px;
    height: 100%;
    border: none;
}
.costum-input>input::-webkit-input-placeholder{
    color: transparent;
}
.costum-input>input:-ms-input-placeholder{
    color: transparent;
}
.costum-input>input::-ms-input-placeholder{
    color: transparent;
}
.costum-input>input::placeholder{
    color: transparent;
}
.costum-input>input.unvalid::-webkit-input-placeholder{
    color: red;
}
.costum-input>input.unvalid:-ms-input-placeholder{
    color: red;
}
.costum-input>input.unvalid::-ms-input-placeholder{
    color: red;
}
.costum-input>input.unvalid::placeholder{
    color: red;
}
.costum-input>input:focus{
    outline: none;
    direction: rtl;
}
.separetor{
    position: relative;
  float: left;
  
   background:#213b7f;
   width: 3px;
   height: 1.3rem;
   border-radius: 20px;
   flex-shrink: 0;
   
}
.privacy-modal{
    position: fixed;
    top: 0px;
    height: 100vh;
    width: 100vw;
    background:  rgba(0,0,0,0.4);
    z-index: 100;  
     padding:0px 5px;
     right: 0px;
     order: 0;
  
}

.modal-privacy-content>h3{
    margin:10px 0px;
    color:#213a7f;
    font-size: 2rem;
    text-align: center;
}
.modal-privacy-content>h6{
    margin: 5px 0px;
    color:#213a7f;
    font-size: 1.2rem;
}
.modal-privacy-content{
    background: white;
    color: black;
  
    overflow-y: scroll;
    direction: rtl;
    height: 98vh;
    padding: 5px;
    width: 92vw;
    font-size: .9rem;
    max-width: 800px;
    position: relative;
    margin: 10px auto;
    padding-bottom: 50px;
    z-index: 99999999999999;

}
.exit-button{
    margin: 5px;
}
.modal-privacy-content.hide{
    display: none;
}
@media (min-width:925px){

}
.email-modal{
    position: fixed;
    top:0px;
    left: 0px;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background: none 0% 0% / auto repeat scroll padding-box border-box rgba(0, 0, 0, 0.31);;
    display: flex;
    justify-content: center;
    align-items: center;
}
.email-modal.hide{
    display: none;
}
.email-modal-content{
    height: 400px;
    width: 600px;
    background: white;
    position: relative;
    padding: 0px 15px;
    padding-top: 25px;
    display: flex;
    flex-flow: column;

    align-items: center;
}
.email-modal-title{
    color: black;
    direction: rtl;
    font-size: 4rem;
    margin:0px;
    margin-bottom: 10px;
    
    margin-top: -10px;
    text-align: center;
  
}
.modal-text{
    text-align: start;
    color: black;
   
    font-size: 1.5rem;
    direction: rtl;

}
.email-modal-content>.fa-check-circle{
    color: green;
    font-size: 5rem;
    align-self: flex-start;
    margin:20px 20px 0px 30px;
}
.email-modal-content>.fa-times-circle{
    color: red;
    font-size: 5rem;
    align-self: flex-start;
    margin:20px 20px 0px 30px;
}
.email-modal-content>.fa-times{
    color: black;
    font-size: 1.8rem;
    margin-top: -10px;
    align-self: flex-start;
    margin-left: 10px;
}
@media(min-width:925px){
    .modal-text{
    font-size: 2.4rem;}
}

.contact-container{
    display: none;
}
.privacy-policy{
    margin-top: .5rem;
    display: block;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    text-decoration: underline;
}
.privacy-policy:hover{

    cursor: pointer;
}
.sumbit-contact{
    padding: 0px;
    align-self: center;
    height: 60px;
    width: 256px;
    margin-top: 10px;
    background: #fff6df;
    border: none;
    margin-bottom: 10%;
}


.sumbit-contact:hover{
   
    cursor: pointer !important;
}
.sumbit-contact-inner-box{
    display: flex;
    border: 2px solid #00B8D4;
   justify-content: center;
   align-items: center;
   height: 96%;
   width: 99%;
   margin: 1px;
   color: black;
   font-size: 2.3rem;
   font-weight: 600;
    direction: ltr;
}
.subjects-list>li{
    margin:15px 5px ;
    font-size: 1.3rem;
    font-weight: 500;
}
.subjects-list>li.ltr-list-item::after,
.subjects-list>li:not(.ltr-list-item)::before{
    content: '\f560';
    font-family: 'Font Awesome 5 Free';
    font-weight:900;
    color: green;
    margin-left: 10px;
}

.subjects-list{
    list-style: none;
    padding: 15px;
    margin: 0px;
}
.ltr-list-item{
   
}
@media (min-width:925px){
.contact-container{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background:linear-gradient(155deg,#154b9c ,#213b7f) !important;
    min-width: 290px;
    height: 490px;
    color: white;
    margin-top: 100px;
    position: relative;
    left: 1vw;

    
}
.contact-container>h2{
    display: block;
    
    align-self: end;
    margin-right: 1.5rem;
    padding-bottom: 15px;
}

.input-container>h2{
    margin: 12px;
    text-align: end;
}
.input-container{
    display: flex;
    
    flex-flow: column;
    align-content: space-between;
    }
    .agree-checkbox{
        margin-top: 5%;
        display: flex;
        align-items:flex-start;
        justify-content: center;
        direction: ltr;
    }
    .checkbox{
        margin-right: 5%;
    }
    .agree-explain{
        margin-right: 2%;
        direction: rtl;
        
        font-size: 13px;
        font-weight: 700;
    }
    
    form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .contact-container.hidden{
       max-height: 50px;
       max-width: 30px;
        justify-content: flex-start;
        overflow: hidden;

    }
}
.banner{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    line-height: 1.1;
    height:130px ;
    width: 100%;
   background:linear-gradient(155deg,#154b9c ,#213b7f) !important;
color: white;
font-weight: 600;

}
.banner-text{
    display: flex;
    flex-flow: column;
    align-items: center;
    font-size: 50px;
 
}
.emphasis{
    display: block;
    font-weight: 800;
font-size: 66px;
}
@media(min-width:925px){
.banner{
    height:190px ;
}
.banner-text{
    font-size: 90px;
    line-height: .8;
}
.emphasis{

font-size: 120px;
}
}
@media (max-width:450px){
    .banner-text{
        font-size:  10vw;
        line-height: 1;
    }
    .emphasis{
        font-size:  15vw;
   
    }
}

.main-page-container{
    display: flex;
    flex-flow: column;
  
}
.courses-explanation-links{
    display: flex;
    
    flex-flow: column;
    justify-content: center;
   
}
.courses-title{
    margin: 25px 0px 25px 0px;

    text-align: center;
  direction: rtl;  
  font-size: 2.9rem;
  overflow: visible;
}
.main-section{
    width: 100%;
    display: flex;
    flex-flow: row-reverse;
    justify-content: space-evenly;
    margin: 0px auto 70px auto;
    max-width: 1400px;
}

.pros-section{
    display: flex;
    flex-flow: column;
   align-items: center;
   justify-content: center;
    width: 100%;
    background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABQAAAAP1AQMAAAAEpdAkAAAABlBMVEXt8vXp8PMayv0PAAADk0lEQVR42u3UMQHAIAADsH77UICQyZoHlOEEKVjY2SMRkaxyecpllMssl7dcvnI55bLLxYMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9OA/FyeQ9QbLaxohAAAAAElFTkSuQmCC) no-repeat;
    background-size: cover;
    
    flex-wrap: wrap;
 
}

.video-container{
    display: block;
    direction: rtl;
    width: 305px;
    height: 215px;
 
    margin: -105px auto 0px auto;
    box-shadow: 3px 3px 7px 0 rgba(139,145,147,.4);
}
.video-img{
    margin: 2px;
    width: 300px;
    height: 170px;
}
.video-more-detail>span{
    margin-left: .4rem;
}
.how-it-work-explain{
    direction: rtl;
    display: flex;
    flex-flow: column;
    margin: 0px 10px;
    font-size: 1rem;
}
.how-it-work-explain>p{
    padding:0px 20px;
    line-height: 1.8rem;
    font-weight: 600;
    font-size: 1.1rem;
}
.title{
    font-size: 2.9rem;
    direction: rtl;
    
    margin: 0px ;
    text-align: center;
}
.process-char{
    margin-top: 80px;
    direction: rtl;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: none;
}
.how-it-work-section{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 0;
    background:#e4e4e4 ;
}
.make-appointment-button{
    padding: 0px;
    align-self: center;
    height: 65px;
    width: 296px;
    margin-top: 10px;
    background: #213a7f;
    border: none;
    margin-bottom: 50px;
    align-self: center;
    margin:40px auto  0px auto;
}

.make-appointment-button:focus{
    border:none;
    outline: none;
}
.make-appointment-button:hover{
   
    cursor: pointer !important;
}
.make-appointment-inner-box{
    display: flex;
    border: 2px solid #aad6be;
   justify-content: center;
   align-items: center;
   height: 96%;
   width: 99%;
   margin: 1px;
   color: white;
   font-size: 1.8rem;
   font-weight: 600;

}
.pros-section-title{
    display: block;
    direction: rtl;
     position: relative;
    font-size: 2.9rem;   
    
  text-align: center;
}
@media (min-width:925px){
    .courses-title{
        position: relative;
        text-align: start;
        margin-right: 65px;
        margin-top: -80px;
      }
        .pros-section-title{
           position: unset;
         text-align: center;
         margin: 40px 0px;
         margin-bottom: 10px;
            
      }
      .advantage-container{
        display: flex;
        flex-flow: row;
        justify-content: center;
       
       max-width: 1260px;

      
      }
      #pros-section>.section-container{
          padding-top:15px ;
      }
      .section-text{
        display: block;
        direction: rtl;
        max-width: 1130px;
        margin: 0px auto;
      
        }
      .pros-section{
         height: 70%;
          margin: 0px;
          padding-bottom: 0px;
          box-shadow: 10px 50px 100px black;
          display: flex;
          justify-content: center;
          
      }
      .video-container{
    align-self: center;
        margin-right: 570px;
        margin-left: 20px;
    z-index: 50;
      }
      .how-it-work-explain{
        flex-flow: row;
        width: 100%;
      }
      .how-it-work-explain{
          align-self: center;
      }
      .how-it-work-main-content{
          display: flex;
          flex-flow: column;
          width: 925px;
          align-self: center;
      }
      .how-it-work-main-content>.title{
margin-right: 0px;
      }
      .process-char{
        margin-top: 80px;
        direction: rtl;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    
      
}
.sub-banner{
  text-align: center;
  font-size: 1.2rem;
  margin-top: 5px;
  padding: 5px;
    color: white;
}
.pro-text{
    direction: rtl;
    margin-right: 5px;
  
}
.blue-emphsis{
    color: blue;
}
.other-places{
    background:black ;
    color:white
}
.difference{
    direction: rtl;
    padding: 10px;
    margin: 10px 0px;
}
.difference>ol>li{
    font-size: 1.1rem;
}

.graduate-companys{
    direction: rtl;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    background:white ;
    box-shadow: 50px 50px 50px #c2b9b0;

}
h2{
    margin: 10px auto;
    text-align: center;  
}
.graduate-companys>h2{
    color: black;
  
}
.difference>ol{
    
    padding-right: 20px;
}
.at-ours{
    background:#eff6df ;
    color: black;

}
.about-us-section{
    direction: rtl;
   background: #e4e4e4;
}

.section-container{
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABQAAAAP1AQMAAAAEpdAkAAAABlBMVEXt8vXp8PMayv0PAAADk0lEQVR42u3UMQHAIAADsH77UICQyZoHlOEEKVjY2SMRkaxyecpllMssl7dcvnI55bLLxYMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9KAHPehBD3rQgx70oAc96EEPetCDHvSgBz3oQQ960IMe9OA/FyeQ9QbLaxohAAAAAElFTkSuQmCC);
    width: 100%;
    
  
    padding: 50px 15px 60px 0px;
   
    
}

.firms-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
}
.firm-logo{
    max-height: 100px;
    max-width: 100px;
    margin: 22px;
    flex-shrink: 0;
    object-fit: fill;
}
.paragraph-link{
    display: inline;
    text-decoration: underline;
    color: white;
    font-weight: 600;
}
.paragraph-link:visited{
    color: white;
}
.quations-answers-section{
 background:#e4e4e4;
}
#quations-answers-section>.section-container{
background: 	#fff6df;
}
@media (min-width:925px){

    .differences-section{
        display: flex;
        justify-content: center;
  
    }
    .difference{
        width: 50%;
        max-width: 550px;
        
        margin:10px;
        padding: 10px;
      
        transition:all .5s linear
    }
    .difference:hover{
       transform: scale(1.1,1.1);
       box-shadow: 10px 10px 50px black;
    }
    .section-container{
        padding: 10px;
        padding-top: 65px;
        
    }
    
    .section-container>p{
        max-width: 1100px;
        margin-right:  auto;
        margin-left: auto
    }
  
    #quations-answers-section{
        height: ;
    }
    .pro-text{
       width:-webkit-max-content;
       width:-moz-max-content;
       width:max-content; 
       direction: rtl;
       text-align: start;
       max-width: 1140px;
    }
    .sub-banner{
        text-align: center;
    font-size: 1.2rem;
    margin-top: 5px;
    padding: 5px;
      color: white;
      width: 1110px;
      position: relative;
      left: 8px;
      margin: 5px auto;
    }
    .section-inner-container{
        display: flex;
        justify-content: center;
        padding-top:50px;
    }
    .quations-answers-container{
        
        display: flex;
        
        
        flex-flow: column;
        flex-wrap: wrap;
       
        
        
    }
    .at-ours{
        order: 1;
    }
    .other-places{
        order: 2;
    }
    .flex-col-wrapper{
        display: flex;
        flex-flow: column;
    }
    
}
.quation-answer-container{
    
    direction: rtl;
    border: .5px solid #dbdbdb;
    padding: 0px 10px;
    border-right: none;
    border-left: none;
   width: 100%;
}
.answer{
    /* font-size: .95rem; */
    max-height: 0px;
    overflow: hidden;
    transition: all .2s ease-in-out;
    
}
.answer-link{
    display: block;
    text-decoration: underline;
    color: blue;
    font-weight: 600;

   
}
.answer-link:visited{
    color: blue;
}
.answer.show{
    max-height: 800px;
    margin-bottom: 15px;
}
.link-inline{
    display: inline;
}
.quation{
    font-weight: 600;
    font-size: 1.1rem;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
    padding: 5px;
    color: #007cba;
   
}
@media (min-width:925px){
    .quation-answer-container{
        width: 450px;
     
    
    flex-grow: 0;
    flex-shrink: 0;
       margin: 0px 17px;
  justify-content: center;
     border-bottom: none;
    }
}
.categories-container {
    padding: 20px;
    width: 100%;
  }
  .categories-container ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    width: 100%;
  }
  
  .category-container {
    padding: 20px 20px;
    margin: 10px 0px;
    width: 100%;
  }
  
  .category-more-link > .fas {
    display: inline;
  }
  
  .plus-icon {
    width: 15px;
    height: 15px;
    margin-left: 12px;
    transition: rotate 0.5s linear;
    rotate: 0deg;
  }
  .plus-icon.become-exit-button {
    rotate: 45deg;
  }
  
  .category-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.3rem;
  }
  
  .category-inner-menu {
    transition: max-height 0.3s linear;
    max-height: 900px;
    opacity: 1;
  }
  .category-inner-menu ul li {
    font-size: 0.9rem;
    margin: 15px 0px;
  }
  @media (min-width: 1205px) {
    .categories-container {
      display: flex;
      justify-content: center;
      margin: 0px auto;
      margin-bottom: 300px;
      width: 100%;
   
      
    }
    .categories-container ul {
      flex-shrink: 0;
      width: 1100px;
      display: grid;
      grid-template-rows: repeat(0, 18.75rem);
      grid-template-columns: repeat(4, 25%);
      display: flex;
      
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    
    .category-container {
      display: flex;
      flex-flow: column;
      height: 65%;
      width: 50%;
      padding: 45px;
      background: none;
      margin: 0px;
    
    }
    .triangle{
      height: 0;
      width: 0;
      border: 25px solid white;
      flex-shrink: 0;
      position: relative;
    }
    .triangle-left{
      float: left;
      margin-top: -60px;
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
      right: 505px;
      top: 50px;
    
    }
    .triangle-down{
      border-right-color: transparent;
      border-bottom-color:transparent;
      border-left-color: transparent;
 
      top: 95px;
      right: 400px;
    
    }
    .triangle-right{
      border-top-color:transparent;
      border-right-color: transparent;
      border-bottom-color:transparent;
      top:-480px;
      left: 95px;
      
    }
    .plus-icon {
      display: none;
    }
  }
  .category-inner-menu.close {
    max-height: 0px;
  }
  
  .take-out-from-flow {
    display: none;
  }
  
  .category-more-link {
    display: flex;
    justify-content: flex-end;
    font-size: 0.9rem;
    align-items: center;
  }
  .category-more-link:link {
    text-decoration: none;
  }
  .category-more-link:visited {
    color: unset;
    text-decoration: none;
  }
  .category-more-link:hover {
    text-decoration: none;
  }
  .category-more-link:active {
    text-decoration: none;
  }
  .category-more-link .fas.fa-arrow-left {
    font-size: 0.9rem;
    margin-right: 5px;
  }
  
  .more-jobs-link {
    background: #d9d6d3;
    color: #202a68;
    font-size: 1.3rem;
    margin: 10px 0px;
    padding: 0px;
  }
  .more-jobs-link .fas.fa-arrow-left {
    background: #fb8e0f;
    height: 100%;
    display: inline;
    padding: 20px 20px;
    color: white;
    font-size: 1.5rem;
  }
  .more-jobs-link a {
    font-size: 1.3rem;
    color: #202a68;
    align-items: center;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
  }
  .more-jobs-link a:link {
    text-decoration: none;
  }
  .more-jobs-link a:visited {
    color: unset;
    text-decoration: none;
  }
  .more-jobs-link a:hover {
    text-decoration: none;
  }
  .more-jobs-link a:active {
    text-decoration: none;
  }
  
  #programing-category {
    background: rgb(5,152,168);
    color: white;
  }
  #programing-category>.triangle-left{
    border-right-color:  rgb(5,152,168);
  }

  #programing-category .category-inner-menu > a:hover,
  #programing-category .category-inner-menu > ul > li:hover {
    color: orange;
  }
  
  #web-category {
    background: rgb(57,164,220);
    color: white;
  }
  #web-category>.triangle-down{
    border-top-color:rgb(57,164,220);
  }
  #web-category .category-inner-menu > a:hover,
  #web-category .category-inner-menu > ul > li:hover {
    color: white;
  }
  
  #it-category {
    background: #213b7f;
    color: white;
  }
  
  #it-category .category-inner-menu > a:hover,
  #it-category .category-inner-menu > ul > li:hover {
    color: #202a68;
  }
  
  #sineor-category {
    background: #ef5227;
    color: white;
  }
  #sineor-category .category-inner-menu > a:hover,
  #sineor-category .category-inner-menu > ul > li:hover {
    color: orange;
  }
  
  #embedded-category {
    background: #0066ff;
    color: white;
  }
  #embedded-category>.triangle-right{
    border-left-color:#0066ff ;
  }
  #embedded-category .category-inner-menu > a:hover,
  #embedded-category .category-inner-menu > ul > li:hover {
    color: orange;
  }
  
  @media (min-width: 1205px) {
    #programing-category {
      grid-row: -3/-2;
      grid-column: 1/2;
      order: 1;
    }
  
    #web-category {
      grid-row: -3/-2;
      grid-column: 2/3;
      order: 2;
    }
  
    #it-category {
     
      grid-row: -3/-2;
      grid-column: 4/5;
      order: 3;
    }
  
  
    #embedded-category {
    
      grid-row: -3/-2;
      grid-column: 3/4;
      order: 4;
    }
  
    .more-jobs-link {
      width: 100%;
      height: 100%;
    }
    .more-jobs-link:hover {
      color: orange;
    }
    .more-jobs-link .fas.fa-arrow-left {
      align-self: flex-end;
      width: 73px;
      height: 73px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .more-jobs-link a {
      padding-right: 40px;
      display: flex;
      width: 100%;
      height: 100%;
    }
  
    .category-inner-menu {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      max-height: 1000px !important;
      height: 100%;
    }
    .category-inner-menu ul {
      display: inline;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
    }
    .category-inner-menu ul li {
      margin: 2px;
    }
  }
.modal-background{
    display: flex;
    position: fixed;
    top: 0;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background: none 0% 0% / auto repeat scroll padding-box border-box rgba(0, 0, 0, 0.31);
    z-index: 500000000;
    transition: all .5s ease-out;
    max-height: 0px;
    overflow: hidden;
    transform: translateY(-100vh);
}
.modal-background.show{
    transform: translateY(0vh);
    max-height: 110vh;
}
.modal{
    z-index: -5;
    background: white;
    position: relative;
    flex-wrap: wrap;
    width: 610px;
    height:  610px;
    border-radius: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    overflow: hidden;
   
 
}
.modal::before{
    content: '';
    background-image: url(/static/media/modal-bg.76e0ddc9.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 160%;
    height: 160%;
    position: absolute;
    background-position: left 0%;
    rotate: 40deg;
}
.modal-text{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
.modal-content{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-flow: column;
    height: 100%;

}
.subtitle{
    color:rgb(120, 42, 169);
    font-size: 1.2rem;
    z-index: 1;
}
input{
    direction: rtl;
}
.online.subtitle{
    font-weight: 600;
    font-size: 1.3rem;
}
.modal-title{
    direction: rtl;
    width: 75%;
    text-align: center;
    color:rgb(21, 75, 156);
    font-weight: 800;
    font-size: 2rem;
    z-index: 1;
    margin: 10px 0px;
}
.modal-form{
    display: flex;
    flex-flow: column;
    align-items: center;
   color: rgb(4, 14, 163);
    width: 55vw;
    max-width: 400px;
    z-index: 1;
    margin-top: -55px;

}
.modal-form>.agree-checkbox{
    margin: 5px 0px 10px 0px;
    
    width: 310px;
}
.modal-form>input{
 width: 10px;
}

.modal-exit-button{
 display: flex;
 justify-content: center;
width: 30px;
height: 30px;
    position: relative;
margin-top: 20px;
    flex-shrink: 0;
}
#exit-line1{
 
   transform: rotate(45deg);
    
}
#exit-line2{
    transform: rotate(130deg);
}
.exit-line{
    width: 2px;
    height: 30px;
    background: black;
}

.modal-button{
    background:rgb(21, 75, 156) ;
    width: 210px;
    border: none;
    font-size:1.3rem;
    color:white;
    
    margin-bottom: 5px;
    height: 50px;
}
.modal-button>.fas{
    font-size: 1rem;
}
.background{
    position: relative;
    z-index:0;
}
.input-wrapper>input[type=text]{
    color: rgb(4, 14, 163);
    font-weight: 800;
    width: 400px;
    height: 7vh;
    margin: 15px 0px;
    padding-right: 5px;
    border:0px solid rgb(53, 47, 138);
    box-shadow:rgb(33, 59, 127) 0px 0px 4px -1px;
}
@media(max-width:450px),(max-height: 450px ){
    .modal-background{
        display: none;
    }
   
}
.modal-input.unvalid::-webkit-input-placeholder{
    color: red;
}
.modal-input.unvalid:-ms-input-placeholder{
    color: red;
}
.modal-input.unvalid::-ms-input-placeholder{
    color: red;
}
.modal-input.unvalid::placeholder{
    color: red;
}

.data-page-logo{
    display: flex;
    flex-flow: column;
    width:70px ;
    height: 70px;
    align-items: center;
   margin: 15px;
   margin-top: 20px;
}
.data-page-logo>img{
    flex-shrink: 0;
    width:60px ;
    height: 60px;
}

.separator{
    width: 80%;
    height: 4px;
    flex-shrink: 0;
    margin: 10px auto;
}
.contact-footer{
    /* margin-top: 50px; */
    padding-bottom: 50px;
    display: flex;
    flex-flow: column;
    background: #213b7f;
    justify-content: center;
    align-items: center;
    color: white;
}
.contact-footer>h2{
    text-align: start;
    direction: rtl;
    font-size: 1.2rem;
    margin:15px 10px ;
    margin-top: 0px;
    padding-top: 10px;
}
.input-container.footer{
    display: flex;
    flex-flow: column;
  width: 100%;
}
.contact-footer>form,
.footer>form{
    width: 70%;
    margin: 0px auto;
}
.sumbit-contact.footer{
   margin: 0px auto;
}
.agree-checkbox.footer{
    margin-top: 10px;
    font-size: .8rem;
    display: flex;
    text-align: start;
    direction: rtl;
    flex-flow: row-reverse;
    justify-content: center
}
.footer-input{
    width: 100%;
    margin: 25px auto;
}
.privacy-policy{
    color:white
}
@media (min-width:925px){
    .contact-footer{
        margin-top: 0px;
        width: 100%;
    }
    
    .input-container.footer{
      
        flex-flow: row-reverse;
       align-items: center;
       justify-content: center;
       flex-wrap: wrap;
      max-width: 950px;
      margin: 0px auto;
    }
    .input-container.footer>.footer-input{
        width: 22%;
        max-width: 260px;
        margin: 25px 5px;
        flex-shrink: 5;
    }
    .sumbit-contact.footer{
    
     }
     .contact-footer>form{
         width: 100%;
     }
}


.data-page{
   
    direction: rtl;
  
}
.data-section{
    padding: 15px;
}
.positions-section{
    background: #eff6df;
}
.data-page-content{
    background:  	#4ac694;
    width: 100%;
   
}
.prerequisites-section{
    background: #bac7b3;
}
.training-section{
    background:  	#e9f9e0;
}
#cyber-data-page-logo>.separator{
    background: #4ac694;
}
#system-admin-data-page-logo>.separator{
    background: #a3b6cc;
}
#marketing-data-page-logo>.separator{
background: #3a5998;
}
#qa-data-page-logo>.separator{
    background: #316cff;
    }
#ui-data-page-logo>.separator{
   background:  #f9cf58;
}
#fullstack-data-page-logo>.separator{
   background:  #bd6ae5;
}
#embedded-data-page-logo>.separator{
    background: #6ea2c5;
}
#mobile-data-page-logo>.separator{
    background: #8e8e93;
}
.main-page-title{
    font-weight: 600;
    margin-top: 5px;
    margin-right: 15px;
}
.data-subtitle{
    font-weight: 600;
    text-align: start;
    margin-right: 7px;
}
.data-section{
    
    padding: 20px;
}
.underline-emphasis{
    text-decoration: underline;
    font-weight:bolder ;
    color: white !important;
}
.section-emphasis{
    
    padding: 10px;
    background: #316cff;
    color: white;
}
@media(min-width:925px){
    .page-wrapper{
        display: flex;
        
    }
    .data-page{
       
        
    }
    .data-page-content{
        background: white;
     
      margin-left:auto ;
      padding-left: 50px ;
     

    }
    .data-section{
        max-width: 800px;
    }
    .data-page-wrapper{
        display: flex;
        justify-content: center;
    }
    .data-page-contact{
        margin-top: 190px;
        position: -webkit-sticky;
        position: sticky;
        top: 95px;
       
        margin-bottom: 10px;
    }
   .data-page-contact.inside-modal-open{
       z-index: 99999;
   }
    .main-page-title{
        padding-right: 10px;
    }
    .start-section{
        padding: 15px;
        padding-left: 50px;
    
        
    }
}
.nav-subtitle{
    color:#7e685a;
    text-align: end;
    margin:2px 10px;
    font-size: 1rem;
}
.whatsapp-connect{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width:255px;
    height: 35px;
    position: fixed;
    background: #0066ff;
    top:95vh;
    right: 1vw;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
    color: white;
    font-weight: 600;
    font-size: 1.3rem;
    border-radius: 15px;
    border:2px solid  #25d366;
}
.whatsapp-connect:hover{
    cursor: pointer;
}
@media (max-width:450px){
    .whatsapp-connect{
        display:none;
    }
}
.contact-bar-container{
   position: -webkit-sticky;
   position: sticky;
    bottom:0px;
    width:100%;
    background: #0066ff;
    display: flex ;
    justify-content: center;
    height: 60px;
}
@media(max-width:925px){
.contact-bar-container{
    display: flex !important;
}
}
.contact-btn{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 500;
    color: white;
    
   
}
.contact-btn:visited,
.contact-btn::selection{
    color: white;
}
.contact-btn:hover>.hover-underline{
    text-decoration: underline;
}
.border-right{
    border-right: 2px solid white;
}
.far.fa-hand-point-up,
.fab.fa-whatsapp{
margin-top: 2px;
    margin-left: 5px;
    
}
html{
    scroll-behavior: smooth;
    
}
body{
    margin: 0px;
 
    overflow-x: hidden;
    overflow-y: visible;
    
   
}
:root{
    --break-point:980px;
}
*{
    box-sizing: border-box;
    font-family: 'Assistant', sans-serif;
    
   
}

p{
    font-size: 1.1rem;
    cursor: default;
}
a:hover{
    cursor: pointer;
}
.hide{
    display: none !important;
}
.green-bg{
    background: green;
}
.cream-bg{
    background: #eff6df;
    color: black
}
.white-bg{
    background: white;
}
.mobile-only{
    display: ;
}
.underline-text{
    text-decoration: underline;
}
.margin-10r{
    margin-right: 10px;
}
.absolute-wrapper{
    position: absolute;
}
.inline-item{
    display: inline;
}
@media (min-width:925px){
    .mobile-only{
        display: none;
    }
}
@keyframes show{
    from{
       transform: translateY(50px);
       opacity: 0;
    }
    to{
       transform: translateY(0px);
       opacity: 1;
  
    }
}
.desktop-only{
    display: none;
}
@media (min-width:925px){
    .desktop-only
    {display: unset;}
}
