
.courses-title{
    display: block;
}
.course-container{
    display: flex;
    height: 153px;
    width: 128px;
    flex-flow: column;
    align-items: center;
   text-align: center;
   line-height: .9rem;
   text-decoration: none;
   margin-left: 20px;
   margin-bottom: 10px;
}

.course-separetor{
    
    width: 40px;
    height: 4px;
    margin: 10px auto 0px auto;
    border-radius: 15px;
    
}
.course-name{
    text-decoration: none;
    color: black;
    text-align: center;
    font-size: 16px;
    margin-top: 5px;
}
.course-logo{
    height: 40px;
    width: 40px;
    height: max-content;
    width: max-content;
}
.course-container:hover{
    opacity: .7;
}
.course-container:focus{
    outline: none;
}
.arrow-container{
    display: none;
}
@media (min-width:925px){
    
    .course-container{
        display: flex;
        
        width: 128px;
        flex-flow: column;
        align-items: center;
        text-align: center;
        line-height: .9rem;
        text-decoration: none;
        margin-left: 20px;
        margin-bottom: 10px;
    }
    .course-separetor{
        flex-shrink: 0;
        margin: 5px 0px;
    }
    
    
}

@media (max-width:450px){
    
    .course-container{
       display: flex;
       flex-flow: row;
       width: 100%;
       height: 45px;
       min-height: 43px;
       min-width: 295px;
       align-items: center;
       
      margin-left: 0px;
      margin-bottom: 5px;
     border: 1px solid rgba(204,204,204,0.5);
     flex-shrink: 0;
      
    }
    .course-separetor{
        width: 4px ;
        height: 30px;
        border-radius: 15px;
        margin: 0px 1px;
        flex-shrink: 0;
    }
    .course_img{
        height: 40px;
        width: 40px;
        
    }
    .course-logo{
        
           
        
        margin-left: 15px;
    }
    .course-name{
        display: flex;
       margin-top: 0px;
        margin-right: 7px;
        text-align: start;
      width: 100%;
        
    }
    .arrow-container{
      display: block;
        width: 10px;
        height: 20px;
        justify-self: center;
        flex-shrink: 0;
    }
       
}



