.data-page-logo{
    display: flex;
    flex-flow: column;
    width:70px ;
    height: 70px;
    align-items: center;
   margin: 15px;
   margin-top: 20px;
}
.data-page-logo>img{
    flex-shrink: 0;
    width:60px ;
    height: 60px;
}

.separator{
    width: 80%;
    height: 4px;
    flex-shrink: 0;
    margin: 10px auto;
}