
.data-page{
   
    direction: rtl;
  
}
.data-section{
    padding: 15px;
}
.positions-section{
    background: #eff6df;
}
.data-page-content{
    background:  	#4ac694;
    width: 100%;
   
}
.prerequisites-section{
    background: #bac7b3;
}
.training-section{
    background:  	#e9f9e0;
}
#cyber-data-page-logo>.separator{
    background: #4ac694;
}
#system-admin-data-page-logo>.separator{
    background: #a3b6cc;
}
#marketing-data-page-logo>.separator{
background: #3a5998;
}
#qa-data-page-logo>.separator{
    background: #316cff;
    }
#ui-data-page-logo>.separator{
   background:  #f9cf58;
}
#fullstack-data-page-logo>.separator{
   background:  #bd6ae5;
}
#embedded-data-page-logo>.separator{
    background: #6ea2c5;
}
#mobile-data-page-logo>.separator{
    background: #8e8e93;
}
.main-page-title{
    font-weight: 600;
    margin-top: 5px;
    margin-right: 15px;
}
.data-subtitle{
    font-weight: 600;
    text-align: start;
    margin-right: 7px;
}
.data-section{
    
    padding: 20px;
}
.underline-emphasis{
    text-decoration: underline;
    font-weight:bolder ;
    color: white !important;
}
.section-emphasis{
    
    padding: 10px;
    background: #316cff;
    color: white;
}
@media(min-width:925px){
    .page-wrapper{
        display: flex;
        
    }
    .data-page{
       
        
    }
    .data-page-content{
        background: white;
     
      margin-left:auto ;
      padding-left: 50px ;
     

    }
    .data-section{
        max-width: 800px;
    }
    .data-page-wrapper{
        display: flex;
        justify-content: center;
    }
    .data-page-contact{
        margin-top: 190px;
        position: sticky;
        top: 95px;
       
        margin-bottom: 10px;
    }
   .data-page-contact.inside-modal-open{
       z-index: 99999;
   }
    .main-page-title{
        padding-right: 10px;
    }
    .start-section{
        padding: 15px;
        padding-left: 50px;
    
        
    }
}